import React from 'react'
import {observer, inject} from 'mobx-react'
import './svgs.scss'
import BlueCircle from './iconbluebg'

import StreakGuage from '../../assets/images/StreakGuage.png'
import StreakGuageRed from '../../assets/images/StreakGuageRed.png'
import StreakGuageFill from '../../assets/images/StreakGuageFill.png'
// import StreakGuageSparkle from '../../assets/images/GaugeSparkle.png'

const white = "#fff"
// const blue = 'rgba(0, 161, 219, 0.8)'
const width = '65'

const StreakIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width={width}
    height={width}
    viewBox='0 0 195 195'
    className="svg"
  >
    <path
      fill={white}
      id='streakicon.svg'
      className='cls-1'
      d='M1868,1052a58,58,0,1,1,58-58A58.067,58.067,0,0,1,1868,1052Zm0-104a46,46,0,1,0,46,46A46.055,46.055,0,0,0,1868,948Zm-5.59,72.39c-1.79-1.31-4.06-1.74-6.12-2.59-7.32-3.05-11.2-11.83-10.06-19.681,0.42-2.942,1.61-6,4.12-7.594-0.09,3.057.18,6.748,2.87,8.2,2.2,1.189,5.05.126,6.77-1.693a9.458,9.458,0,0,0,2.43-7.926c-0.56-3.354-2.89-6.135-3.98-9.354a14.672,14.672,0,0,1,1.67-12.4A17.766,17.766,0,0,1,1870.42,960a14.1,14.1,0,0,0,1.59,12.827c3.04,4.343,8.24,6.577,12.08,10.232a22.212,22.212,0,0,1,6.19,20.251,23.451,23.451,0,0,1-13.55,16.41c-2.12.91-4.35,1.52-6.48,2.4-2.04.84-3.8,2.66-5.65,3.38C1864.33,1023.54,1864.27,1021.76,1862.41,1020.39Z'
      transform='translate(-1771 -897)'
    />
  </svg>
)

@inject('snowball')
@observer
class Streak extends React.Component {
  render () {
    return (<div className='svgContainer'>
      <div className='heart-icon-container'>
        <StreakIcon />
        <BlueCircle streak={this.props.snowball.streak} />
      </div>
      <div
        className={`streak-guage-container streak-${this.props.snowball.streak}`}
      >
        <img
          id='streak-guage'
          src={this.props.snowball.streak === 3 ? StreakGuageRed : StreakGuage}
          alt=''
        />
        <div className='fill-to-show'>
          <img id='streak-guage-fill' src={StreakGuageFill} alt='' />
        </div>
        {this.props.snowball.streak === 3 && <p id="multiplyer">2x</p>}
      </div>
    </div>)
  }
}
export default Streak