import {observable, action} from 'mobx'

class SledStore {
  constructor(rootStore) {
    this.rootStore = rootStore
  }

  @observable view = 'landing'
  @observable score = 1000
  @observable time = 60
  @observable hud = false

  @action setView = view => {
    this.view = view
  }
  @action setScore = score => {
    this.score = score
  }
  @action setTime = time => {
    this.time = time
  }
  @action setHud = visible => {
    this.hud = visible
}
}

export default SledStore
