import React from 'react'
import styled from 'styled-components'
import ExperienceLoader from '../scripts/ExperienceLoader'
import LandingScreen from '../components/gameplay/portal/LandingScreen'
import HUDScreen from '../components/gameplay/portal/HUDScreen'

import Config from '../config'

export default class PortalGame extends React.Component {
  state = {
    landing: true,
    loaded: false
  }

  componentDidMount = () => {
    ExperienceLoader.load('Portal').then(this.onLoadModule)
  }

  onLoadModule = module => {
    this.game = new module.default()
    this.game.load().then(() => {
      this.game.init(document.getElementById('ar-canvas'), this.hitarea)  
      this.setState({ loaded: true })
    })
  }

  closeHandler () {
    window.location.href = Config.get('url') + 'unlimited'
    
  }

  gameAction = (screen, action = null) => {
    if (this.state.loaded) {
      if (screen === 'landing') {
        this.setState({ landing: false, })
      }

      if (screen === 'hud') {
        if (action === 'reset') {
          // window.XR.XrController.updateCameraProjectionMatrix({
          //   origin: { x: 0, y: 0, z: 0 },
          //   facing: { w: 0, x: 0, y: 0, z: 0 }
          // })
          // window.XR.XrController.recenter()
          this.game.recenter()
        }
        if (action === 'close') {
          this.closeHandler()
        }
      }
    }
  }

  render = () => (
    <Container ref={r => this.container = r} className="portal-game">
      <div className={
        `overlay
        ${this.state.landing ? '' : 'hide'}
      `}></div>
      <div className='frost'></div>
      { this.state.landing && <LandingScreen loaded={this.state.loaded} action={this.gameAction}/> }
      { this.state.loaded && !this.state.landing && <HUDScreen action={this.gameAction}/> }
       <HitArea ref={r => this.hitarea = r}></HitArea>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;`

const HitArea = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`