import React from 'react';
import { Grid } from '@material-ui/core';
import joyToWorldImg from './joy-to-the-world.png';
import errorRibbon from '../../../assets/images/horizontal-ribbon1.png'
import './styles.css';

const ErrorPage = () => (

  <div>
    <Grid
      className="errorPage"
      container
      justify="center"
      alignItems="center"
      direction="column"
    >
      <img src={errorRibbon} alt="Ribbon" className="ribbon-desktop-error" />
      <img src={joyToWorldImg} alt="Joy to the World" className="joy-to-world" />
      <p className="desktop-err-text"
        style={{
          fontSize: '1.65rem',
          lineHeight: '1.36',
          letterSpacing: '0.13px',
          margin: '2.125rem',
          zIndex: 1,
          textAlign: 'center',
          padding: '2% 0 0 0'
        }}
      >
        Just not from a desktop or tablet. You&apos;ll need a smart phone to
        unlock this webpage.
        </p>
    </Grid>
  </div>
);

export default ErrorPage;