import React from 'react'
import Lottie from 'react-lottie'
import { isAndroid } from 'react-device-detect'

import animation from './animation';

class Animation extends React.Component {
  state = {
    animePaused: false,
    height: (window.innerWidth < window.innerHeight && window.innerWidth >= 322) ? 207 : 70
  }
  
  componentDidMount() {
    window.addEventListener('orientationchange', () => {
      var viewport = document.querySelector('meta[name="viewport"]')

      if (window.orientation === -90 || window.orientation === 90) {
        this.setState({ height: 70 })
        viewport.content = "width=device-width"
      } 
      if (window.orientation === 0) {
        this.setState({ height: 200 })

        if (isAndroid) {
          viewport.content = "width=380"
        }
      }
    })
  }

  render = () => (
    <Lottie
      options={{
        animationData: animation,
        autoplay: true
      }}
      height={this.state.height}
      isStopped={this.state.animePaused}
    />
  )
}

export default Animation
