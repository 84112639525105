import React from 'react'
import CloseButton from './../CloseButton'
import Reposition from '../../../assets/images/RepostionCamera.png'
import Div100vh from 'react-div-100vh'

import '../../../pages/GamePages.scss'

class HUDScreen extends React.Component {

  resetHandler = () => {
    if (this.props.action) {
      this.props.action('hud', 'reset')
    }
  }

  close = () => {
    if (this.props.action) {
      this.props.action('hud', 'close')
    }
  }
  
  render () {
    return (
      <Div100vh>
        <div className='hud'>  
          <div className="reposition-container" >
            <img className="reposition" id='reposition' src={Reposition} alt='' onClick={this.resetHandler} />
          </div>
          <CloseButton id="end" click={this.close}/>
        </div>
      </Div100vh>
    )
  }
}
export default HUDScreen
