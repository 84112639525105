import React from 'react'
import '../../../pages/GamePages.scss'
import InternetSpeedSledImg from '../../../assets/images/internet-speed-sled.png'
import Ribbon from '../../../assets/images/horizontal-ribbon1.png'

export default class SnowballLanding extends React.Component {
  state = {
    mounted: false,
  }

  componentDidMount () {
    this.setState({mounted: true})
  }
  render = () => {
    return (
      <div className={`landing-screen transparent-blue-bg hide-overflow ${this.state.mounted ? 'mounted' : ''}`} style={{position: 'relative', overflow: 'hidden'}} onClick={() => { this.props.action('landing') }}>
        <img src={Ribbon} alt="Ribbon" className="internet-speed-sled-ribbon" /> 
        <img src={InternetSpeedSledImg} alt="Internet Speed Sled" class="internet-speed-sled-img" />
        <div className='content'>
          {this.props.loaded && (
            <div className='copy internet-speed-sled-content'>
              <p>Aim your phone out in front of you and angled slightly down.</p>
              <div>
                <div><strong>Tap the screen.</strong></div>
                <div>when you are ready to play.</div>
              </div>
            </div>
          )}
          {!this.props.loaded && (
            <div className='copy'>
              <p>
                <strong>Loading...</strong>
              </p>
            </div>
          )}
        </div>
      </div>
    )
  }
}
