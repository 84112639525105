import React from 'react'
// import SnowballStore from '../../state/SnowballStore'

const red = 'rgba(228, 97, 93, 0.8)'
const blue = 'rgba(0, 161, 219, 0.8)'

function BlueCircle (streak) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg" 
        width="75" 
        height="75" 
        viewBox="0 0 106 106"
        className="blue-circle"
      >
        <circle
          fill={streak.streak === 3 ? red : blue}
          id='iconbluebg'
          className='cls-1'
          cx='53'
          cy='53'
          r='53'
        />
      </svg>
    </div>
  )
}

export default BlueCircle