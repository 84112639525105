import React from 'react'
import { observer, inject } from 'mobx-react'
// import CloseButton from './../CloseButton'
import Timer from '../../svgs/timericon'
import Map from '../../../assets/images/Map.png'
import Reposition from '../../../assets/images/RepostionCamera.png'
import Arrows from '../../../assets/images/SledGameArrows.png'

import '../../../pages/GamePages.scss'


@inject('sled')
@observer
class HUDScreen extends React.Component {

  resetHandler = () => {
    if (this.props.action) {
      this.props.action('hud', 'reset')
    }
  }
  // formatScore (score) {
  //   return score.toLocaleString('en')
  // }

  pause = () => {
    if (this.props.action) {
      this.props.action('hud', 'pause')
    }
  }

  render () {
    return (
      <div className='hud sled'>
        <div className='controls-container'>
          <div className='row'>
            <img id="map" src={Map} alt=""/>
            <p>{this.props.score}</p>
            <span id="feet">FT</span>
          </div>
        </div>
        <div className='reposition-container'>
          <img className="reposition" id='reposition' src={Reposition} alt='' onClick={this.resetHandler}
          />
        </div>
        <div className='pause-container'>
          <div className='row'>
            <Timer />
            <p>{this.props.time}</p>
          </div>
          <div className='pause-button' onClick={this.pause}></div>
        </div>
        <div className="gaming-arrows">
          <img className="arrows up" src={Arrows}/>
          <img className="arrows down" src={Arrows}/>
        </div>
      </div>
    )
  }
}
export default HUDScreen
