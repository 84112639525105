import React from 'react'
import Rotate from '../../assets/images/RotateArt.png'
import ribbonImg from '../../assets/images/vertical-ribbon.png'

export default class RotateScreen extends React.Component {
  render = () => (
    <div className="rotate hide-overflow transparent-blue-bg" onClick={() => { this.props.action('rotate') }}>
      <img src={ribbonImg} alt="Ribbon" className="vertical-ribbon-rotate" />
      <div className="content">
        <div className='title'>
          <h1 className="rotate-your-screen-text-title">Rotate</h1>
          <h1 className="rotate-your-screen-text-secondary">your screen</h1>
        </div>
        <img src={Rotate} alt="rotate phone" />
      </div>
    </div>
  )
}