import React from 'react'
import SnowEffect from '../../SnowEffect'
import { ButtonBase } from '@material-ui/core'

import '../../../pages/GamePages.scss'

export default class SnowballLanding extends React.Component {
  state = {
    mounted: false,
  }

  componentDidMount () {
    this.setState({mounted: true})
  }
  render = () => {
    return (
      <div className={`landing-screen ${this.state.mounted ? 'mounted' : ''}`} onClick={() => { this.props.action(true) }}>
        <SnowEffect />
        <div className='content'>
          {this.props.loaded && (
            <div className='copy'>
              <p>Holiday joy for all to see? It’s made by elves and sent to your TV.</p>
              <ButtonBase onClick={() => { this.props.action(true)}} class="button">Take a look</ButtonBase>
            </div>
          )}
          {!this.props.loaded && (
            <div className='copy'>
              <p><strong>Loading...</strong></p>
            </div>
          )}
        </div>
      </div>
    )
  }
}
