import React from 'react'
import BlueCircle from '../svgs/iconbluebg'

import './svgs.scss'

const Timer = ({
  style = {},
  fill = '#fff',
  width = '70'
}) => (
  <div className="svgContainer">
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={width}
      height={width}
      viewBox='0 0 211 231'
      className="svg"
    >
      <path
        fill={fill}
        id='timericon.svg'
        className='cls-1'
        d='M1878,681.881V675h4V661h-28v14h4v6.881A58,58,0,1,0,1878,681.881ZM1868,785a46,46,0,1,1,46-46A46.049,46.049,0,0,1,1868,785Zm-0.23-55.977a9.9,9.9,0,0,0-5.55,1.842l-0.04.012,0.01,0.012a9.972,9.972,0,0,0,11.04,16.6l0.01,0.016,0.04-.042a10.017,10.017,0,0,0,3.88-4.466L1898,719Z'
        transform='translate(-1774 -625)'
      />
    </svg>
    <BlueCircle />
  </div>
)

export default Timer