import img0 from './Bodymovin/Onboarding/images/img_0.png';
import img1 from './Bodymovin/Onboarding/images/img_1.png';
import img2 from './Bodymovin/Onboarding/images/img_2.png';

let jsonAnime;

if (img0 !== undefined && img1 !== undefined && img2 !== undefined) {
  jsonAnime = {
    v: '5.4.1',
    fr: 29.9700012207031,
    ip: 0,
    op: 360.000014663101,
    w: 414,
    h: 300,
    nm: 'Comp 7',
    ddd: 0,
    assets: [
      { id: 'image_0', w: 185, h: 380, u: '', p: img0, e: 0 },
      { id: 'image_1', w: 1920, h: 399, u: '', p: img1, e: 0 },
      { id: 'image_2', w: 500, h: 500, u: '', p: img2, e: 0 },
      {
        id: 'comp_0',
        layers: [
          {
            ddd: 0,
            ind: 1,
            ty: 2,
            nm: 'Phone.png',
            cl: 'png',
            refId: 'image_0',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    n: ['0p833_0p833_0p167_0p167'],
                    t: 0,
                    s: [7],
                    e: [-7]
                  },
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    n: ['0p833_0p833_0p167_0p167'],
                    t: 60,
                    s: [-7],
                    e: [0]
                  },
                  { t: 115.000004684046 }
                ],
                ix: 10
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    n: '0p833_0p833_0p167_0p167',
                    t: -35.07,
                    s: [294.5, 166.5, 0],
                    e: [295.34, 166.5, 0],
                    to: [-10.4088611602783, 0, 0],
                    ti: [18.042667388916, 0, 0]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    n: '0p667_1_0p167_0p167',
                    t: 0,
                    s: [295.34, 166.5, 0],
                    e: [177.501, 166.5, 0],
                    to: [-20.7069644927979, 0, 0],
                    ti: [28.0332679748535, 0, 0]
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.333, y: 0 },
                    n: '0p833_0p833_0p333_0',
                    t: 60,
                    s: [177.501, 166.5, 0],
                    e: [208.5, 166.5, 0],
                    to: [-0.9358292222023, 0, 0],
                    ti: [-4.98568201065063, 0, 0]
                  },
                  { t: 114.930004681195 }
                ],
                ix: 2
              },
              a: { a: 0, k: [92.5, 190, 0], ix: 1 },
              s: { a: 0, k: [55.838, 49.579, 100], ix: 6 }
            },
            ao: 0,
            ip: 0,
            op: 360.000014663101,
            st: 0,
            bm: 0
          },
          {
            ddd: 0,
            ind: 2,
            ty: 2,
            nm: 'AOX-Pano.jpg',
            cl: 'jpg',
            refId: 'image_1',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    n: ['0p833_0p833_0p167_0p167'],
                    t: 114,
                    s: [0],
                    e: [100]
                  },
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    n: ['0p833_0p833_0p167_0p167'],
                    t: 120,
                    s: [100],
                    e: [100]
                  },
                  { t: 148.000006028164 }
                ],
                ix: 11
              },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [67, 150, 0], ix: 2 },
              a: { a: 0, k: [960, 199.5, 0], ix: 1 },
              s: { a: 0, k: [49, 49, 100], ix: 6 }
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
              {
                inv: false,
                mode: 'a',
                pt: {
                  a: 0,
                  k: {
                    i: [[0, 0], [0, 0], [0, 0], [0, 0]],
                    o: [[0, 0], [0, 0], [0, 0], [0, 0]],
                    v: [
                      [1330.743, 157.663],
                      [1167.143, 157.663],
                      [1167.143, 288.104],
                      [1330.743, 288.104]
                    ],
                    c: true
                  },
                  ix: 1
                },
                o: { a: 0, k: 100, ix: 3 },
                x: { a: 0, k: 0, ix: 4 },
                nm: 'Mask 2'
              }
            ],
            ip: 0,
            op: 360.000014663101,
            st: 0,
            bm: 0
          },
          {
            ddd: 0,
            ind: 3,
            ty: 2,
            nm: 'AOX-Pano.jpg',
            cl: 'jpg',
            refId: 'image_1',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    n: ['0p833_0p833_0p167_0p167'],
                    t: 114,
                    s: [100],
                    e: [100]
                  },
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    n: ['0p833_0p833_0p167_0p167'],
                    t: 119,
                    s: [100],
                    e: [100]
                  },
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    n: ['0p833_0p833_0p167_0p167'],
                    t: 120,
                    s: [100],
                    e: [46.586]
                  },
                  { t: 141.000005743048 }
                ],
                ix: 11
              },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [67, 150, 0], ix: 2 },
              a: { a: 0, k: [960, 199.5, 0], ix: 1 },
              s: { a: 0, k: [49, 49, 100], ix: 6 }
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
              {
                inv: false,
                mode: 'a',
                pt: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.833, y: 0.833 },
                      o: { x: 0.167, y: 0.167 },
                      n: '0p833_0p833_0p167_0p167',
                      t: 0,
                      s: [
                        {
                          i: [[0, 0], [0, 0], [0, 0], [0, 0]],
                          o: [[0, 0], [0, 0], [0, 0], [0, 0]],
                          v: [
                            [1539.427, 87.572],
                            [1350, 64.72],
                            [1312.19, 378.137],
                            [1501.617, 400.989]
                          ],
                          c: true
                        }
                      ],
                      e: [
                        {
                          i: [[0, 0], [0, 0], [0, 0], [0, 0]],
                          o: [[0, 0], [0, 0], [0, 0], [0, 0]],
                          v: [
                            [1368.565, 74.739],
                            [1170.561, 74.995],
                            [1175.048, 393.85],
                            [1372.492, 391.274]
                          ],
                          c: true
                        }
                      ]
                    },
                    {
                      i: { x: 0.833, y: 0.833 },
                      o: { x: 0.167, y: 0.167 },
                      n: '0p833_0p833_0p167_0p167',
                      t: 30,
                      s: [
                        {
                          i: [[0, 0], [0, 0], [0, 0], [0, 0]],
                          o: [[0, 0], [0, 0], [0, 0], [0, 0]],
                          v: [
                            [1368.565, 74.739],
                            [1170.561, 74.995],
                            [1175.048, 393.85],
                            [1372.492, 391.274]
                          ],
                          c: true
                        }
                      ],
                      e: [
                        {
                          i: [[0, 0], [0, 0], [0, 0], [0, 0]],
                          o: [[0, 0], [0, 0], [0, 0], [0, 0]],
                          v: [
                            [1305.801, 69.861],
                            [1109.505, 81.705],
                            [1129.592, 401.745],
                            [1327.127, 386.491]
                          ],
                          c: true
                        }
                      ]
                    },
                    {
                      i: { x: 0.833, y: 0.833 },
                      o: { x: 0.167, y: 0.167 },
                      n: '0p833_0p833_0p167_0p167',
                      t: 44,
                      s: [
                        {
                          i: [[0, 0], [0, 0], [0, 0], [0, 0]],
                          o: [[0, 0], [0, 0], [0, 0], [0, 0]],
                          v: [
                            [1305.801, 69.861],
                            [1109.505, 81.705],
                            [1129.592, 401.745],
                            [1327.127, 386.491]
                          ],
                          c: true
                        }
                      ],
                      e: [
                        {
                          i: [[0, 0], [0, 0], [0, 0], [0, 0]],
                          o: [[0, 0], [0, 0], [0, 0], [0, 0]],
                          v: [
                            [1273.835, 66.725],
                            [1078.638, 86.019],
                            [1108.752, 406.821],
                            [1306.345, 383.416]
                          ],
                          c: true
                        }
                      ]
                    },
                    {
                      i: { x: 0.667, y: 1 },
                      o: { x: 0.167, y: 0.167 },
                      n: '0p667_1_0p167_0p167',
                      t: 53,
                      s: [
                        {
                          i: [[0, 0], [0, 0], [0, 0], [0, 0]],
                          o: [[0, 0], [0, 0], [0, 0], [0, 0]],
                          v: [
                            [1273.835, 66.725],
                            [1078.638, 86.019],
                            [1108.752, 406.821],
                            [1306.345, 383.416]
                          ],
                          c: true
                        }
                      ],
                      e: [
                        {
                          i: [[0, 0], [0, 0], [0, 0], [0, 0]],
                          o: [[0, 0], [0, 0], [0, 0], [0, 0]],
                          v: [
                            [1263.215, 65.827],
                            [1068.333, 87.255],
                            [1108.13, 406.005],
                            [1298.93, 382.536]
                          ],
                          c: true
                        }
                      ]
                    },
                    {
                      i: { x: 0.833, y: 0.833 },
                      o: { x: 0.333, y: 0 },
                      n: '0p833_0p833_0p333_0',
                      t: 60,
                      s: [
                        {
                          i: [[0, 0], [0, 0], [0, 0], [0, 0]],
                          o: [[0, 0], [0, 0], [0, 0], [0, 0]],
                          v: [
                            [1263.215, 65.827],
                            [1068.333, 87.255],
                            [1108.13, 406.005],
                            [1298.93, 382.536]
                          ],
                          c: true
                        }
                      ],
                      e: [
                        {
                          i: [[0, 0], [0, 0], [0, 0], [0, 0]],
                          o: [[0, 0], [0, 0], [0, 0], [0, 0]],
                          v: [
                            [1309.716, 71.135],
                            [1117.194, 80.177],
                            [1129.904, 397.157],
                            [1324.786, 387.255]
                          ],
                          c: true
                        }
                      ]
                    },
                    {
                      i: { x: 0.833, y: 0.833 },
                      o: { x: 0.167, y: 0.167 },
                      n: '0p833_0p833_0p167_0p167',
                      t: 97,
                      s: [
                        {
                          i: [[0, 0], [0, 0], [0, 0], [0, 0]],
                          o: [[0, 0], [0, 0], [0, 0], [0, 0]],
                          v: [
                            [1309.716, 71.135],
                            [1117.194, 80.177],
                            [1129.904, 397.157],
                            [1324.786, 387.255]
                          ],
                          c: true
                        }
                      ],
                      e: [
                        {
                          i: [[0, 0], [0, 0], [0, 0], [0, 0]],
                          o: [[0, 0], [0, 0], [0, 0], [0, 0]],
                          v: [
                            [1343.658, 75.01],
                            [1152.858, 75.01],
                            [1152.858, 390.699],
                            [1343.658, 390.699]
                          ],
                          c: true
                        }
                      ]
                    },
                    { t: 114.930004681195 }
                  ],
                  ix: 1
                },
                o: { a: 0, k: 100, ix: 3 },
                x: { a: 0, k: 0, ix: 4 },
                nm: 'Mask 1'
              }
            ],
            ip: 0,
            op: 360.000014663101,
            st: 0,
            bm: 0
          },
          {
            ddd: 0,
            ind: 4,
            ty: 2,
            nm: 'Gift Bubble.ai',
            cl: 'ai',
            refId: 'image_2',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    n: '0p833_0p833_0p167_0p167',
                    t: 172,
                    s: [244.5, 132.5, 0],
                    e: [293.5, 104.5, 0],
                    to: [8.16666698455811, -4.66666650772095, 0],
                    ti: [-8.16666698455811, 4.66666650772095, 0]
                  },
                  { t: 186.000007575935 }
                ],
                ix: 2
              },
              a: { a: 0, k: [250, 250, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                    n: [
                      '0p833_0p833_0p167_0p167',
                      '0p833_0p833_0p167_0p167',
                      '0p833_0p833_0p167_0p167'
                    ],
                    t: 174,
                    s: [0, 0, 100],
                    e: [11, 11, 100]
                  },
                  { t: 188.000007657397 }
                ],
                ix: 6
              }
            },
            ao: 0,
            ip: 0,
            op: 360.000014663101,
            st: 0,
            bm: 0
          },
          {
            ddd: 0,
            ind: 5,
            ty: 1,
            nm: 'Black Solid 1',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    n: ['0p833_0p833_0p167_0p167'],
                    t: 112,
                    s: [0],
                    e: [100]
                  },
                  { t: 118.000004806239 }
                ],
                ix: 11
              },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [207, 150, 0], ix: 2 },
              a: { a: 0, k: [207, 150, 0], ix: 1 },
              s: { a: 0, k: [100, 100, 100], ix: 6 }
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
              {
                inv: false,
                mode: 'a',
                pt: {
                  a: 0,
                  k: {
                    i: [[0, 0], [0, 0], [0, 0], [0, 0]],
                    o: [[0, 0], [0, 0], [0, 0], [0, 0]],
                    v: [
                      [256.613, 87.5],
                      [160, 87.5],
                      [160, 248.445],
                      [256.613, 248.445]
                    ],
                    c: true
                  },
                  ix: 1
                },
                o: { a: 0, k: 100, ix: 3 },
                x: { a: 0, k: 0, ix: 4 },
                nm: 'Mask 1'
              }
            ],
            sw: 414,
            sh: 300,
            sc: '#000000',
            ip: 0,
            op: 360.000014663101,
            st: 0,
            bm: 0
          }
        ]
      }
    ],
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 0,
        nm: 'Comp 3',
        refId: 'comp_0',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                n: ['0p833_0p833_0p167_0p167'],
                t: 0,
                s: [0],
                e: [100]
              },
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                n: ['0p833_0p833_0p167_0p167'],
                t: 5,
                s: [100],
                e: [100]
              },
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                n: ['0p833_0p833_0p167_0p167'],
                t: 345,
                s: [100],
                e: [0]
              },
              { t: 359.00001462237 }
            ],
            ix: 11
          },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [207, 135, 0], ix: 2 },
          a: { a: 0, k: [207, 150, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                n: ['0p667_1_0p333_0', '0p667_1_0p333_0', '0p667_1_0p333_0'],
                t: 175,
                s: [100, 100, 100],
                e: [134, 134, 100]
              },
              { t: 210.000008553475 }
            ],
            ix: 6
          }
        },
        ao: 0,
        w: 414,
        h: 300,
        ip: 0,
        op: 360.000014663101,
        st: 0,
        bm: 0
      }
    ],
    markers: []
  };
}
export default jsonAnime;
