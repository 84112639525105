import React from 'react'
import { ButtonBase } from '@material-ui/core'
import Distance from '../../../assets/images/Instructions-Distance.png'
import Timer from '../../../assets/images/TimerIcon.png'
import TwoThumbs from '../../../assets/images/TwoThumbsIcon.svg'
import Boost from '../../../assets/images/BoostIcon.png'
import Shield from '../../../assets/images/ShieldIcon.png'
import MoreTime from '../../../assets/images/MoreTimeIcon.png'
import CloseButton from '../CloseButton'

// Slick Carousel
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

class NextButton extends React.Component {
  enterGame = () => {
    this.props.click(true)
  }
  
  render() {
    const { currentSlide, slideCount, onClick } = this.props
    const lastSlide = currentSlide === slideCount - 1

    const finalWording = this.props.showCloseButton ? 'Continue' : 'Play'
    const finalFunction = this.props.showCloseButton ? this.enterPause : this.enterGame

    return (
      <ButtonBase className='button' onClick={ lastSlide ? finalFunction : onClick } >
        { lastSlide ? finalWording : 'Next' }
      </ButtonBase>
    )
  }
}

class Instructions extends React.Component {
  state = {
    mounted: false,
  }

  settings = {
    dots: true,
    arrows: true,
    autplay: false,
    infinite: true,
    nextArrow: <NextButton click={() => { this.play() }} />
  }

  close = () => {
    this.props.action('help', 'close')
  }

  play = () => {
    if (this.props.action) {
      this.props.action('help', 'go')
    }
  }

  componentDidMount () {
    this.setState({mounted: true})
  }

  render () {
    return (
      <div className={`instructions ${this.state.mounted ? 'mounted' : ''}`}>
        <Slider {...this.settings} >
          <div className='instruction-screen'>
            <div className="image-with-icon-container">
              <div className='image-with-icon'>
                <p>1000</p>
                <img src={Distance} alt='' />
              </div>
              <div className='image-with-icon'>
                <img id="timer" src={Timer} alt='' />
                <p>01:00</p>
              </div>
            </div>
            <p>Go far, go fast, before time runs out.</p>
          </div>

          <div className='instruction-screen'>
            <div className='image-container'>
              <img id='two-thumbs' src={TwoThumbs} alt='' />
            </div>
            <p>Tap the right and left sides of your screen to avoid obstacles.</p>
          </div>

          <div className='instruction-screen wide'>
            <div className='icon-wrapper'>
              <div className='icon-container'>
                <img id='speed' src={Boost} alt='' />
                <p>Boost your speed!</p>
              </div>
              <div className='icon-container'>
                <img id='shield' src={Shield} alt='' />
                <p>Plow through obstacles!</p>
              </div>
              <div className='icon-container'>
                <img id='more-time' src={MoreTime} alt='' />
                <p>Extend your time!</p>
              </div>
            </div>
          </div>
        </Slider>
        { !this.props.showCloseButton ? <span onClick={this.play}>SKIP</span> : <CloseButton click={() => { this.close() }}/>}
      </div>
    )
  }
}

export default Instructions