import React from 'react'
import { ButtonBase } from '@material-ui/core'
import Flick from '../../../assets/images/FlickIcon.svg'
import Streak from '../../../assets/images/StreakIcon.png'
import Health from '../../../assets/images/HealthIcon.png'
import MoreTime from '../../../assets/images/MoreTimeIcon.png'
import CloseButton from '../CloseButton'

// Slick Carousel
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

class NextButton extends React.Component {
  enterGame = () => {
    this.props.click('instructions', 'game')
  }

  enterPause = () => {
    this.props.click('instructions', 'pause')
  }

  render() {
    const {currentSlide, slideCount, onClick} = this.props
    const lastSlide = currentSlide === slideCount - 1

    const finalWording = this.props.showCloseButton ? 'Continue' : "Let's Play"
    const finalFunction = this.props.showCloseButton ? this.enterGame : this.enterPause

    return (
      <ButtonBase className="button" onClick={ lastSlide ? finalFunction : onClick } >
        { lastSlide ? finalWording : 'Next' }
      </ButtonBase>
    )
  }
}

class Instructions extends React.Component {
  state = {
    mounted: false,
  }

  settings = {
    dots: true,
    arrows: true,
    autplay: false,
    infinite: true,
    nextArrow: <NextButton click={() => { this.play() }} />
  }

  close = () => {
    this.props.action('instructions', 'close')
  }

  play = () => {
    if (this.props.action) {
      this.props.action('instructions', 'play')
    }
  }

  componentDidMount () {
    this.setState({mounted: true})
  }

  render = () => (
    <div className={`instructions ${this.state.mounted ? 'mounted' : ''}`}>
      <Slider {...this.settings}>
        <div className='instruction-screen'>
          <div className='image-container'>
            <img src={Flick} alt='' />
          </div>
          <p>Swipe to throw snowballs and keep foes at bay.</p>
        </div>

        <div className='instruction-screen'>
          <div className='image-container'>
            <img className='guage' src={Streak} alt='' />
          </div>
          <p>Consecutive hits earn you a bonus streak!</p>
        </div>

        <div className='instruction-screen'>
          <div className='image-container'>
            <img className='guage health' src={Health} alt='' />
          </div>
          <p>You can take 5 hits before your snowy opponents claim victory.</p>
        </div>

        <div className='instruction-screen'>
          <div className='image-container'>
            <img src={MoreTime} alt='' />
          </div>
          <p>Look for time bonuses.</p>
        </div>
      </Slider>
      { !this.props.showCloseButton ? <span onClick={this.play}>SKIP</span> : <CloseButton click={() => { this.close() }}                                                                                                   />}
    </div>
  )
}

export default Instructions