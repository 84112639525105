import React from 'react'
import Rotate from '../../assets/images/RotateArt.png'
import ribbonImg from '../../assets/images/horizontal-ribbon1.png'

export default class RotateScreenLandscape extends React.Component {
  render = () => (
    <div className="rotate hide-overflow transparent-blue-bg" onClick={() => { this.props.action('rotate') }}>
      <img src={ribbonImg} alt="Ribbon" className="horizontal-ribbon-rotate" />
      <div className="content">
        <div className="rotate-landscape">
          <div className="rotate-your-screen-text-title landscape-view">Rotate</div>
          <div className="rotate-your-screen-text-secondary landscape-view">your screen</div>
        </div>
        <img src={Rotate} style={{transform: 'rotate(-90deg)'}} alt="rotate phone" />
      </div>
    </div>
  )
}