import React from 'react'
import { ButtonBase } from '@material-ui/core'
import { inject, observer } from 'mobx-react'

@inject('experience')
@observer
class TargetFoundCta extends React.Component {
  state = {
    id: '',
  }
  close = () => {
    this.props.experience.toggleModal(false)
  }
  componentDidMount () {
    console.log('prop', this.props.id)
    let newId = ''
    
    if (this.props.id === 'sled') {
      newId = 'internet'
    }
    if (this.props.id === 'portal') {
      newId = 'unlimitied'
    }
    if (this.props.id === 'snowball') {
      newId = 'prepaid'
    }
    if (this.props.id === 'window') {
      newId = 'directv'
    }

    this.setState({id: newId})
  }

  render = () => (
    <div className={`target-found ${this.props.target ? 'slide-in' : ''}`}>
      <div className='close' onClick={this.close}></div>
      <div className='content'>
        <h1>Cool!</h1>
        <p>You found a target</p>
        <ButtonBase id={this.state.id} className='button' onClick={() => {
          this.props.click()
        }}>
          Continue
        </ButtonBase>
      </div>
    </div>
  )
}

export default TargetFoundCta
