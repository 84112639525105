import React from 'react'
import styled from 'styled-components'
import ExperienceLoader from '../scripts/ExperienceLoader'
import LandingScreen from '../components/gameplay/window/LandingScreen'
import CloseButton from '../components/gameplay/CloseButton'

import Config from '../config'

export default class WindowGame extends React.Component {
  state = {
    landing: true,
    loaded: false
  }

  componentDidMount = () => {
    ExperienceLoader.load('Window').then(this.onLoadModule)
  }

  onLoadModule = module => {
    this.game = new module.default()
    this.game.load().then(() => {
      this.game.init(document.getElementById('ar-canvas'))
      this.setState({ loaded: true })
    })
  }

  closeHandler () {
    window.location.href =  Config.get('url') + 'directv'
  }

  gameAction = () => {
    if (this.state.loaded) {
      this.setState({ landing: false })
      this.game.activate()
    }
  }

  render = () => (
    <Container ref={r => this.container = r} className="window-game">
      <div className={
        `overlay
        ${this.state.landing ? '' : 'hide'}
        `}></div>
      <div className='frost'></div>
      { this.state.landing && <LandingScreen loaded={this.state.loaded} action={this.gameAction}/> }
      { this.state.loaded && !this.state.landing && <CloseButton id="end" click={this.closeHandler}/> }
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`