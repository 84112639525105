import React, { Component } from 'react';
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import ExperienceLoader from '../scripts/ExperienceLoader'

import LandingScreen from '../components/gameplay/snowball/LandingScreen'
import WaveScreen from '../components/gameplay/snowball/WaveScreen'
import HUDScreen from '../components/gameplay/snowball/HUDScreen'
import Instructions from '../components/gameplay/snowball/Instructions'
import GamePausedScreen from '../components/gameplay/GamePausedScreen'
import GameOverScreen from '../components/gameplay/GameOverScreen'
import RotateScreen from '../components/gameplay/RotateScreen'
import RotateScreenLandscape from '../components/gameplay/RotateScreenLandscape'

import Config from '../config'

@inject('snowball')
@observer
class SnowballGame extends Component {
  state = {
    loaded: false,
    rotate: false,
    openedFromPauseScreen: false,
  }

  componentDidMount () {
    ExperienceLoader.load('Snowball').then(this.onLoadModule)
    this.setOrientation()
  }

  setOrientation = () => {
    const store = this.props.snowball
    if (window.innerHeight < window.innerWidth) {
      this.setState({ rotate: true })
    }
    window.addEventListener('orientationchange', () => {
      if(window.orientation === 0){
        this.setState({ rotate: false })
      }else {
        this.setState({ rotate: true })
        if(store.view === "game"){
          this.pauseGame()
        }
      }
    }, false)
  }
  onLoadModule = (module) => {
    this.game = new module.default()
    this.game.load().then(() => {
      this.game.init(document.getElementById('ar-canvas'), this.hitarea)
      this.setState({ loaded: true })
      // if (localStorage.getItem('dev')) {
      //   window.XR.XrController.recenter()
      //   this.props.snowball.setView('game')
      //   this.props.snowball.setHud(true)
      //   this.game.initLevel()
      // }
    })
  }

  pauseGame = () => {
    const store = this.props.snowball
    this.game.pause()
    store.setView('paused')
    store.setHud(false)
  }

  gameAction = (screen, action = null) => {
    const store = this.props.snowball
    if (this.state.loaded) {
      if (screen === 'landing') {
        if(localStorage.getItem('sb.inst') === '1'){
          store.setView('wave')  
        } else {
          store.setView('instructions')
        }
        
      }
  
      if (screen === 'hud') {
        if (action === 'reset') {
          window.XR.XrController.recenter()
        }
        if (action === 'pause') {
          this.pauseGame()
        }
      }
  
      if (screen === 'paused') {
        if (action === 'restart') {
          this.game.restartGame(true)
          store.setView('wave')
        }
        if (action === 'quit') {
          window.location.href = Config.get('url') + 'prepaid'
        }
        if (action === 'help') {
          store.setView('help')
          this.setState({ openedFromPauseScreen: true })
        }
        if (action === 'cancel') {
          store.setView('game')
          store.setHud(true)
          this.game.resume()
        }
      }
  
      if (screen === 'gameover') {
        if (action === 'restart') {
          this.game.restartGame()
          store.setView('wave')
        }
        if (action === 'quit') {
          window.location.href = Config.get('url') + 'prepaid'
        }
      }
  
      if (screen === 'instructions') {
        if(store.view === 'help') {
          store.setView('paused')
        } else {
          localStorage.setItem('sb.inst', '1')
          store.setView('wave')
        }
      }
  
      if (screen === 'wave') {
        store.setView('game')
        store.setHud(true)
        this.game.initLevel()
        setTimeout(() => {
          if(store.view === 'game' && this.state.rotate === true) {
            this.pauseGame()
          }
        }, 1000)
      }
    }
  }

  render () {
    const { snowball } = this.props
    return (<Container ref={r => this.container = r} className="snowball-game">
      <div className={
        `overlay 
        ${snowball.view === 'paused' ? 'pause' : ''}
        ${snowball.view === 'landing' || snowball.view === 'gameover' || snowball.view === 'instructions'  || snowball.view === 'paused'|| snowball.view === 'help' ? '' : 'hide'}
      `}></div>
      
      <div className={`hit ${snowball.hit === true ? 'active': ''}`}></div>
      <HitArea ref={r => this.hitarea = r} active={snowball.hud}></HitArea>
      { this.state.rotate && <RotateScreenLandscape action={this.gameAction} /> }
      { snowball.hud ? 
      <>
        <HUDScreen action={this.gameAction} />
        <div className={`frost`}></div>
      </>
       : null }
      { snowball.view === 'landing' && <LandingScreen loaded={this.state.loaded} action={this.gameAction} />}
      { snowball.view === 'wave' && <WaveScreen action={this.gameAction}/>}
      { (snowball.view === 'help' || snowball.view === 'instructions') && !this.state.rotate && <Instructions action={this.gameAction} showCloseButton={this.state.openedFromPauseScreen}/>}
      { snowball.view === 'paused' && <GamePausedScreen action={this.gameAction}/>}
      { snowball.view === 'gameover' && <GameOverScreen score={snowball.score} action={this.gameAction}/>}
    </Container>)
  }
}

export default SnowballGame

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const HitArea = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${props => {
    return props.active === true ? "block;" : "none;"
  }}
`