import React from 'react'
import LetItThrowImg from '../../../assets/images/let-it-throw.png'
import Ribbon from '../../../assets/images/vertical-ribbon.png'
import '../../../pages/GamePages.scss'

export default class SnowballLanding extends React.Component {
  state = {
    mounted: false,
  }

  componentDidMount () {
    this.setState({mounted: true})
  }
  
  render = () => {
    return (
      <div className={`landing-screen transparent-blue-bg hide-overflow ${this.state.mounted ? 'mounted' : ''}`} onClick={() => { this.props.action('landing') }}>
        <img src={Ribbon} alt="Ribbon" className="let-it-throw-ribbon" />
        <img src={LetItThrowImg} alt="Let It Throw" className="let-it-throw-img" />
        <div className='content'>
          {this.props.loaded && (
            <div className='copy'>
              <p>Aim your phone down a little.</p>
              <div>
                <strong>Tap the screen</strong>
                <p>when you are ready to start</p>
              </div>
            </div>
          )}
          {!this.props.loaded && (
            <div className='copy'>
              <p>
                <strong>Loading...</strong>
              </p>
            </div>
          )}
        </div>
      </div>
    )
  }
}
