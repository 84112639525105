import React from 'react'
// import './ARLoadingScreen.scss'
import Snowman from '../assets/images/Snowman.png'
import { ButtonBase } from '@material-ui/core'
import logo from '../assets/images/unwrap-joy.png'
import Ribbon from '../assets/images/vertical-ribbon.png'
import RibbonHorizontal from '../assets/images/horizontal-ribbon1.png'
//import ARLoadingScreen from './ARLoadingScreen'

const button = false

export default class ARErrorScreen extends React.Component {

  render = () => (
    <div className='ar-loading-screen error hide-overflow'>
              <img
          src={logo}
          alt="Unwrap Joy"
          style={{margin: '25px auto 15px'}}
          className="unwrap-joy-img-home"
        />
        <img
          src={Ribbon}
          alt="Ribbon"
          className="unwrap-joy-ribbon-home"
        />
        <img
          src={RibbonHorizontal}
          alt="Ribbon"
          className="unwrap-joy-ribbon-horizontal-home"
        />
      <div className='loading-animation'>
        <div className='circle'>
          <img className='image' src={Snowman} alt='' />
        </div>
        <div className='text'>
          <h1>Whoops!</h1>
          <p>Unfortunately, you can’t unwrap holiday fun with your device. Talk to a sales rep to find your next phone.</p>
          { button && <ButtonBase className="button">Button</ButtonBase> }
        </div>
      </div>
      
    </div>
  )
}
