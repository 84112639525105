import ExperienceStore from 'state/ExperienceStore'
import SnowballStore from 'state/SnowballStore'
import SledStore from 'state/SledStore'

class RootStore {
  constructor() {
    this.experience = new ExperienceStore(this)
    this.snowball = new SnowballStore(this)
    this.sled = new SledStore(this)
  }
}

export default new RootStore()
