// import Config from './config'
import React from 'react'
import { observer, Provider } from 'mobx-react'
import { isMobileOnly, isIOS, isMobileSafari, osVersion } from 'react-device-detect';
import RootStore from './state/RootStore'
import ARLoader from './scripts/ARLoader'
import AssetLoader from './scripts/AssetLoader'
import Homepage from './components/Homepage'
import LandingExperience from './components/LandingExperience'
import SledExperience from './components/SledExperience'
import SnowballExperience from './components/SnowballExperience'
import PortalExperience from './components/PortalExperience'
import WindowExperience from './components/WindowExperience'
import Config from './config'
import Data from './data/app'
import ARErrorScreen from './components/ARErrorScreen'

import './App.scss'
import './LoadingScreen.scss'
import './components/Homepage.scss'
import './components/SnowEffect.scss'

import ErrorPage from 'components/homepage/ErrorPage'
import GTM from './scripts/GTM'

// @observer

class App extends React.Component {
  state = {
    arLoaded: false,
    experience: Config.get('experience'),
    loadHome: true
  }

  componentDidMount = () => {
    document.addEventListener('scroll', (e) => {
      e.preventDefault()
    })

    console.log('osVersion', parseInt(osVersion))

    if (window.location.pathname === '/experience') {
      this.setState({ loadHome: false })
      this.homepageClick()
      this.changeView('')
    }

    AssetLoader.preload(Data.assets.ui)
  }

  homepageClick = (e) => {
    // GTM.trackEvent('Open Camera', 'Experience')
    
    ARLoader.load().then(() => {
      const experience = this.getExperienceFromUrl()

      this.setState({ arLoaded: true, experience }) // For testing before routing
      //this.setState({ arLoaded: true, experience: 'sled' }) // For testing before routing
      // this.setState({ arLoaded: true})
    })
  }

  getExperienceFromUrl = () => {
    const href = window.location.href
    const host = window.location.protocol + '//' + window.location.host
    let slug = href.substr(host.length + 1)

    if (slug) {
      slug = slug.toLowerCase()
    }

    switch(slug) {
      case 'internetspeedsled':
        return 'sled'
      case 'letitthrow':
        return 'snowball'
      case 'unwrapjoy':
        return 'portal'
      // case 'window':
      //   return 'window'
      default:
        return null
    }
  }

  changeView = (view) => {
    this.setState({
      experience: view
    })
  }

  render () {
    if (isMobileOnly) {
      if (isIOS && isMobileSafari && parseInt(osVersion) < 11) {
        return <ARErrorScreen/>
      }
      return (
        <Provider
          experience={RootStore.experience}
          snowball={RootStore.snowball}
          sled={RootStore.sled}
        >
          <div className="app">
            {!this.state.arLoaded && this.state.loadHome && <Homepage click={this.homepageClick} />}
            {this.state.arLoaded && this.state.experience === '' && <LandingExperience change={this.changeView}/>}
            {this.state.arLoaded && this.state.experience === 'sled' && <SledExperience/>}
            {this.state.arLoaded && this.state.experience === 'snowball' && <SnowballExperience/>}
            {this.state.arLoaded && this.state.experience === 'portal' && <PortalExperience />}
            {this.state.arLoaded && this.state.experience === 'window' && <WindowExperience />}
          </div>
        </Provider>
      )
    }
    return <ErrorPage/>

  }
}

export default App
