import React from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import ExperienceLoader from '../scripts/ExperienceLoader'
import Div100vh from 'react-div-100vh'

import LandingScreen from '../components/gameplay/sled/LandingScreen'
import HUDScreen from '../components/gameplay/sled/HUDScreen'
import GameOverScreen from '../components/gameplay/sled/GameOverScreen'
import GamePausedScreen from '../components/gameplay/GamePausedScreen'
import Instructions from '../components/gameplay/sled/Instructions'
import RotateScreen from '../components/gameplay/RotateScreen'

import Config from '../config'

@inject('sled')
@observer
class SledGame extends React.Component {
  state = {
    loaded: false,
    indicator: false,
    indicatorAngle: 0,
    rotate: false,
    ended: false,
    score: 0,
    time: '1:00',
    openedFromPauseScreen: false,
  }
  componentDidMount = () => {
    ExperienceLoader.load('Sled').then(this.onLoadModule)
    this.setOrientation()
    this.container.addEventListener('touchstart', this.onTouchHandler, true)
  }

  componentWillUnmount = () => {
    clearInterval(this.updateInterval)
    this.unmounted = true
  }

  restart = () => {
    this.game.restart()
    this.setState({ score: 0, ended: false, time: '1:00' })
    this.props.sled.setView('game')
    this.props.sled.setHud(true)
  }

  onTouchHandler = e => {
    if (!e.target.classList.contains('reposition') && !e.target.classList.contains('pause-button') && this.game && this.game.started && this.game.clock.running) {
      if (e.touches.length > 2) {
        return
      }
      let x = (e.touches[0].clientX / window.innerWidth) * 2 - 1
      // let y = - (e.touches[0].clientY / window.innerHeight) * 2 + 1
      if (x <= 0) {
        this.game.onSwipeDown()
      } else {
        this.game.onSwipeUp()
      }
    }
  }

  setOrientation = () => {
    if (window.innerHeight > window.innerWidth) {
      this.setMustRotate()
    }
    window.addEventListener('orientationchange', () => {
      window.orientation === 0 ? this.setMustRotate() : this.unsetMustRotate()
    }, false)
  }

  setMustRotate = () => {
    this.setState({ rotate: true })
    if (this.game && this.props.sled.view !== 'pause' && this.props.sled.view === 'game') {
      this.game.pause()
    }
  }

  unsetMustRotate = () => {
    this.setState({ rotate: false })
    if (this.props.sled.view !== 'pause' && this.props.sled.view === 'game') {
      this.game.unpause()
    }
  }

  onLoadModule = module => {
    this.game = new module.default()
    this.game.load().then(() => {
      this.game.init(this.canvas)
      this.setState({ loaded: true })
      this.onExperienceLoad()
    })
  }

  onExperienceLoad = () => {
    this.updateInterval = setInterval(this.onUIUpdate, 100)
    // this.onUpdate()
  }

  onUIUpdate = () => {
    const time = new Date(Math.round(this.game.time) * 1000).toISOString().substr(14, 5)
    if (!this.game.ended) {
      this.setState({ time, score: this.game.score })
    }

    if (!this.state.ended && this.game.ended) {
      this.props.sled.setView('gameover')
      this.setState({ ended: true, time: '00:00' })
    }
  }

  closeHandler() {
    window.location.href = Config.get('url') + 'internet'
  }

  startGame() {
    const store = this.props.sled
    this.game.start()
    store.setHud(true)
    store.setView('game')
    this.game.unpause()
  }

  gameAction = (screen, action = null) => {
    const store = this.props.sled
    if (this.state.loaded) {

      if (screen === 'rotate') {
        store.setView('landing')
      }

      if (screen === 'landing') {
        if(localStorage.getItem('sled.inst') === '1') {
          this.startGame()
        } else{
          store.setView('help')
        }
        
      }
      
      if (screen === 'help') {
        if (!this.state.openedFromPauseScreen) {
          this.startGame()
          localStorage.setItem('sled.inst', '1')
        } else {
          store.setView('pause')
        }
      }

      if (screen === 'hud') {
        if (action === 'reset') {
          window.XR.XrController.recenter()
        }
        if (action === 'pause') {
          store.setView('pause')
          store.setHud(false)
          this.game.pause()
        }
      }

      if (screen === 'paused') {
        if (action === 'restart') {
          this.restart()
        }
        if (action === 'quit') {
          window.location.href = Config.get('url') + 'internet'
        }
        if (action === 'help') {
          store.setView('help')
          this.setState({ openedFromPauseScreen: true })
        }
        if (action === 'cancel') {
          store.setView('game')
          store.setHud(true)
          this.game.unpause()
        }
      }

      if (screen === 'gameover') {
        if (action === 'restart') {
          this.restart()
        }
        if (action === 'quit') {
          window.location.href = Config.get('url') + 'internet'
        }
      }
    }
  }

  render () {
    const { sled } = this.props
    return(
      <Div100vh>
        <Container ref={r => (this.container = r)} className='sled-game'>
          <div className={
            `overlay
            ${sled.view === 'pause' ? 'pause' : ''}
            ${sled.view === 'game' && !this.state.rotate ? 'hide' : ''}
          `}></div>
          { !this.state.ended && !this.state.rotate && sled.hud ? 
          (
          <>
            <HUDScreen time={this.state.time} score={this.state.score} action={this.gameAction} /> 
            <div className='frost'></div>
          </>
          ) : null }
          { !this.state.ended && !this.state.rotate && sled.view === 'landing' && <LandingScreen loaded={this.state.loaded} action={this.gameAction} />}
          { !this.state.ended && !this.state.rotate && sled.view === 'pause' && <GamePausedScreen action={this.gameAction} /> }
          { !this.state.ended && !this.state.rotate && sled.view === 'help' && <Instructions action={this.gameAction} showCloseButton={this.state.openedFromPauseScreen}/> }
          { !this.state.rotate && sled.view === 'gameover' && (
            <GameOverScreen score={this.state.score} action={this.gameAction} />
          ) }
          { this.state.rotate && <RotateScreen action={this.gameAction} /> }
        </Container>
      </Div100vh>
    )
  }
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
export default SledGame