import { observable, computed, action } from 'mobx'

const STREAK_MAX = 3
const TIME = 120

class SnowballStore {
  constructor(rootStore) {
    this.rootStore = rootStore
  }

  @observable view = 'landing'

  @observable score = 0
  @observable time = TIME
  @observable health = 5
  @observable streak = 0
  @observable wave = 1
  @observable hud = false
  @observable hit = false
  @observable launched = false
  @observable direction = 'none'

  @computed get multiplier () {
    let base = 1
    if(this.streak === STREAK_MAX){
      base = base * 2
    }
    return base * this.wave
  }

  @action setView = (view) => {
    this.view = view
  }
  @action setHud = (visible) => {
    this.hud = visible
  }

  @action setWave = (wave) => {
    this.wave = wave
  }

  @action setGameDefaults = () => {
    this.score = 0
    this.health = 5
    this.time = TIME
    this.streak = 0
    this.wave = 1
  }

  @action setScore = (score) => {
    this.score = score
  }
  @action updateScore = (score, useMultiplier = true) => {
    let multiplier = useMultiplier ? this.multiplier : 1
    this.score = this.score + (score * multiplier)
  }

  @action setTime = (time) => {
    this.time = time
  }
  @action setHealth = (health) => {
    this.health = health
  }
  @action setStreak = (streak) => {
    if (streak >= 0 && streak <= STREAK_MAX) {
      this.streak = streak
    }
  }

  @action setHit = () => {
    if(this.hit === false){
      this.hit = true
      setTimeout(() => {
        this.hit = false
      },300 )
    }
  } 

  @action setLaunched = (launched) => {
    this.launched = launched
  }

  @action setDirection = (direction) => {
    this.direction = direction
  }
  
}

export default SnowballStore