import React from 'react'
import {observer, inject} from 'mobx-react'
import './svgs.scss'
import BlueCircle from './iconbluebg'

const white = "#fff"
// const blue = 'rgba(0, 161, 219, 0.8)'
const width = '65'

const HealthIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink" 
    width={width}
    height={width}
    viewBox="0 0 195 195"
    className="svg"
    >
    <path
      fill={white}
      id="healthicon" 
      className="cls-1"
      d="M1868,924a58,58,0,1,1,58-58A58.067,58.067,0,0,1,1868,924Zm0-104a46,46,0,1,0,46,46A46.055,46.055,0,0,0,1868,820Zm-0.72,71.517c39.68-15.665,31.33-62.661,0-41.774C1835.95,828.856,1827.59,875.852,1867.28,891.517Z" 
      transform="translate(-1771 -769)"
    />
  </svg>
)

@inject('snowball')
@observer
class Health extends React.Component {
  render () {
    return (
      <div className='svgContainer'>
        <div className='heart-icon-container'>
          <HealthIcon />
          <BlueCircle />
        </div>
        <div className='heart-guage-container'>   
          <div className={`health-line ${this.props.snowball.health > 0 ? 'active' : ''}`}></div>
          <div className={`health-line ${this.props.snowball.health > 1 ? 'active' : ''}`}></div>
          <div className={`health-line ${this.props.snowball.health > 2 ? 'active' : ''}`}></div>
          <div className={`health-line ${this.props.snowball.health > 3 ? 'active' : ''}`}></div>
          <div className={`health-line ${this.props.snowball.health > 4 ? 'active' : ''}`}></div>
        </div>
      </div>
    )
  }
}

export default Health