import debug from 'debug'
import Config from '../config'

const log = debug('helper:GTM')

class GTM {
  gtm (eventName, options = {}) {
    console.log('DataLayer', `event ${eventName}`, options)
    // if (Config.get('isProduction') === true) {
      window.dataLayer.push({
        event: eventName,
        ...options,
      })
    // }
  }

  trackEvent (label, category, options = {}) {
    this.gtm('customEvent', {
      label,
      category,
      type: 'click',
      ...options,
    })
  }

  trackPageView (pageName) {
    this.gtm('customPageview', {
      pageName,
    })
  }

  trackTime (classes, element, id, time ) {
    this.gtm('customTimerEvent', {
      classes,
      element,
      id,
      time,
    })
  }
}
export default new GTM()
