import React from 'react'
import { inject } from 'mobx-react'

import '../../../pages/GamePages.scss'
import SnowEffect from 'components/SnowEffect'

@inject('snowball')

class WaveScreen extends React.Component {

  componentDidMount () {
    setTimeout(() => {
      this.props.action('wave')
    }, 1500)
  }

  render () {
    return (
      <div className='wave-screen'>
        <h2 className='extra fancy'>Wave</h2>
        <h3 className='extra fancy'>{this.props.snowball.wave}</h3>
      </div>
    )
  }
}
export default WaveScreen