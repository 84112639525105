import Config from './config'

const production = {
  isLocal: false,
  isProduction: true,
  experience: '',
  url: '/'
}

const dev = {
  ...production,
  isLocal: true,
  isProduction: false,
}

// initialize config
Config.default(production)
.environment(/cobydev/, {...dev, experience: 'window'})
  .environment(/arizonadev/, {...dev, experience: 'window'})
  .environment(/bryandev/, {...dev, experience: 'sled'})
  .environment(/jareddev/, {...dev, experience: 'portal'})
  .environment(/travisdev/, {...dev, experience: 'window'})
  .environment(/savanadev/, {...dev, experience: ''})
  .detect()

export default Config
