
import React from 'react'
import { ButtonBase } from '@material-ui/core'
import Ribbon from '../../../assets/images/horizontal-ribbon1.png'

export default class GameOver extends React.Component {
  
  playAgain = () => {
    if (this.props.action) {
      this.props.action('gameover', 'restart')
    }
  }

  quit = () => {
    if (this.props.action) {
      this.props.action('gameover', 'quit')
    }
  }

  render () {
    return (
      <div className='game-over sled hide-overflow transparent-blue-bg'>
        <img src={Ribbon} alt="Ribbon" className="game-over-horizontal-ribbon" /> 
        <div className='content'>
          <div className='title'>
            <h1 className='extra fancy'>Game Over</h1>
          </div>
        </div>
        <div className='score'>
          <span>You went</span>
          <h3>{this.props.score}</h3>
          <span>feet</span>
        </div>
        <div className='buttons landscape-game-over'>
          <ButtonBase className="button" onClick={this.playAgain}>Play Again</ButtonBase>
          <ButtonBase className="button" onClick={() => {
                //this.quit
                window.location.href = 'https://unlock.att.com/joy/';
              }}>Quit</ButtonBase>
        </div>
      </div>
    )
  }
}
