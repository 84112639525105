
import React from 'react'
import { ButtonBase } from '@material-ui/core'
import Ribbon from '../../assets/images/vertical-ribbon.png'

export default class GameOver extends React.Component {
  
  playAgain = () => {
    if (this.props.action) {
      this.props.action('gameover', 'restart')
    }
  }

  quit = () => {
    if (this.props.action) {
      this.props.action('gameover', 'quit')
    }
  }

  render () {
    return (
      <div className='game-over transparent-blue-bg hide-overflow'>
        <img src={Ribbon} alt="Ribbon" className="game-over-snowball" />
        <div className='content'>
          <div className='title'>
            <h1 className='fancy'>Game</h1>
            <h1 className='fancy'>over</h1>
          </div>
        </div>
        <div className='score'>
          <span>Your score</span>
          <h3>{this.props.score}</h3>
        </div>
        <div className='buttons'>
          <ButtonBase className="button" onClick={this.playAgain}>Play Again</ButtonBase>
          <ButtonBase className="button" id="end" onClick={() => {
            //this.quit
            window.location.href = 'https://unlock.att.com/joy/';
          }}>Quit</ButtonBase>
        </div>
      </div>
    )
  }
}
