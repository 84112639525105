import React, { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
// import { ButtonBase } from '@material-ui/core'
import { Grid, ButtonBase, makeStyles, SwipeableDrawer } from '@material-ui/core'

import Animation from './homepage/Animation'
import CarouselCopy from './homepage/CarouselCopy'
import logo from '../assets/images/unwrap-joy.png'
import placeholder from '../assets/images/oval@3x.png'
import Ribbon from '../assets/images/vertical-ribbon.png'
import RibbonHorizontal from '../assets/images/horizontal-ribbon1.png'
import Yeti from '../assets/images/Yeti.png'

import '../index.scss'

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: 'ATT Aleck Sans',
    color: 'white',
    margin: '0',
    padding: '0'
  }, 
  margin: {
    margin: theme.spacing(1)
  },
  text: {
    fontFamily: 'ATT Aleck Sans',
    letterSpacing: '3px',
    fontWeight: 'bold',
    fontSize: '.9rem',
    color: '#0a71ae'
  },
  rippleVisible: {
    borderRadius: '30px'
  }
}))

const Homepage = props => {
  const classes = useStyles(props)
  const [open, setOpen] = useState(false)
  const [cookieOpen, setCookieOpen] = useState(true)
  const [modalClicked, setModalClick] = useState(false)
  const [cookies, setCookie] = useCookies(['accept'])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (cookies.accept !== undefined) {
      setCookieOpen(false);
    }
  }, [cookies.accept])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setModalClick(true)
  }

  const handleCookieClose = () => {
    setCookieOpen(false)
    setCookie('accept', true)
  }

  const renderCarousel = () => {
    if (!cookieOpen || cookies.accept) {
      return <CarouselCopy />
    }
  }

  const renderPlaceholder = () => {
    return <img className="static" src={placeholder} alt="static phone" />
  }

  const renderAnime = () => {
    return <Animation className="animation" />
  }

  return (
    <div>
      <Grid
        className={`${classes.root} home hide-overflow homepage-container`}
        container
        direction="column"
        alignItems="center"
        justify="flex-start"
        style={{ height: '100%', width: '100%' }}
      >
        <img
          src={logo}
          alt="Unwrap Joy"
          style={{margin: '25px auto 15px'}}
          className="unwrap-joy-img-home"
        />
        <img
          src={Ribbon}
          alt="Ribbon"
          className="unwrap-joy-ribbon-home"
        />
        <img
          src={RibbonHorizontal}
          alt="Ribbon"
          className="unwrap-joy-ribbon-horizontal-home"
        />
        
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="flex-end"
          style={{ height: '70%', 'flex-wrap': "nowrap" }}
          className="content"
        >
          <div className="yeti-circle">
            <img
              src={Yeti}
              alt="Yeti"
              className="yeti-img"
            />
        </div>

          <ButtonBase className='button' onClick={props.click}>Open Camera</ButtonBase>
        </Grid>
      </Grid>
      {!cookies.accept && (
        <SwipeableDrawer
          onOpen={() => {}}
          onClose={() => {
            renderAnime()
          }}
          anchor="bottom"
          open={cookieOpen}
          className={cookieOpen ? `${classes.root}` : `${classes.root} cookie`}
          style={{ margin: 0, padding: 0 }}
        >
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className="modalBackground"
          >
            <p className="alert">This website uses cookies</p>
            <p className="accept">
              By continuing to use this site, you accept our use of cookies
            </p>

            <ButtonBase
              color="primary"
              variant="contained"
              className="button"
              style={{
                backgroundColor: '#fff',
                width: '18.125rem',
                height: '3.9375rem',
                boxShadow: '0 2px 126px 0 rgba(4, 86, 131, 0.69)',
                margin: '1rem'
              }}
              onClick={handleCookieClose}
            >
              I ACCEPT
            </ButtonBase>
          </Grid>
        </SwipeableDrawer>
      )}
    </div>
  )
}

export default Homepage
