import React from 'react'
import { observer, inject } from 'mobx-react'
// import CloseButton from './../CloseButton'
import Timer from '../../svgs/timericon'
import Health from '../../svgs/healthicon'
import Streak from '../../svgs/streakicon'
import Reposition from '../../../assets/images/RepostionCamera.png'
import Flick from '../../../assets/images/FlickIconDropShadow.png'
import Arrow  from '../../../assets/images/offscreen_arrow_blue.png'

import '../../../pages/GamePages.scss'

@inject('snowball')
@observer
class HUDScreen extends React.Component {

  resetHandler = () => {
    if (this.props.action) {
      this.props.action('hud', 'reset')
    }
  }

  formatTime (time) { 
    const m = Math.floor((time % 3600) / 60)
    const s = time % 60
    let sTime = s < 10 ? '0' + s : s
    return `0${m}:${sTime}`
  }

  formatScore (score) {
    return score.toLocaleString('en')
  }

  pause = () => {
    // if (this.props.action) {
    this.props.action('hud', 'pause')
    // }
  }
  
  render () {

    const { snowball } = this.props

    return (<div className='hud'>
      <div className='controls-container'>
        <div className='row'>
          <Timer />
          <p>{this.formatTime(this.props.snowball.time)}</p>
        </div>
        <div className={`row health`}>
          <Health />
        </div>
        <div className={`row streak`}>
          <Streak />
        </div>
      </div>
      <div className="reposition-container" >
        <img className="reposition" id='reposition' src={Reposition} alt='' onClick={this.resetHandler} />
      </div>
      <div className='pause-container'>
        <p className='score'>{this.formatScore(this.props.snowball.score)}</p>
        <div className='pause-button' onClick={this.pause}></div>
      </div>
      <div className = 'arrow-container'>
        <img className={`arrow left ${snowball.direction === 'left' ? 'show' : ''}`} src={Arrow} alt='Arrow'/>
        <img className={`arrow right ${snowball.direction === 'right' ? 'show' : ''}`} src={Arrow} alt='Arrow'/>
      </div>
      <div className={`swipe-to-throw ${snowball.launched ? 'hide' : ''}`}>
        <img src={Flick} alt="flick"/>
        <p>Swipe up to throw</p>
      </div>
    </div>)
  }
}
export default HUDScreen
